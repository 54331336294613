:root {
  --dark-bg: #343d46;
  --spacing: 50px;
  --accent-color: #F3F3F3;
  --text-color: #f3f3f3;
  --box-bg: #343d46c6;
  --transition: all 0.3s ease;
  --header-bg: #343d46fa;
  --footer-bg: #343d46fa;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: var(--dark-bg);
  color: var(--text-color);
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.fonts-loaded body {
  font-family: 'brandon-grotesque', sans-serif;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--header-bg);
  z-index: 100;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

nav ul li {
  padding: 1rem;
}

nav ul li a {
  position: relative;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.25rem;
  transition: var(--transition);
}

nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--accent-color);
  transition: var(--transition);
}

nav ul li a:hover::after {
  width: 100%;
}

.navbar {
  background-color:var(--header-bg);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid white;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  padding: 0 20px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: auto;
  width: 161px;
  transition: transform 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.1);
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-menu li {
  margin-right: 1.5rem;
}

.nav-menu a {
  color: var(--text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.nav-menu a:hover {
  color: var(--accent-color);
}

.login-button {
  background-color: var(--accent-color);
  color: var(--dark-bg);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: var(--header-bg);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-menu li {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .menu-icon span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: var(--text-color);
  }

  .menu-icon.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-icon.open span:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .login-button {
    position: absolute;
    top: 22px;
    right: 70px;
  }
}

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--accent-color);
  color: var(--dark-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.7;
}

.scroll-top:hover {
  opacity: 1;
  transform: translateY(-3px);
}

@media screen and (min-width: 961px) {
  .scroll-top {
    display: none;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.main-content {
  display: flex;
  justify-content: center;
  padding: 120px 0;
}

.price-calculator, .mailing-list {
  text-align: center;
}

.price-calculator h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.price-calculator form div {
  margin-bottom: 1rem;
}

.price-calculator input {
  width: 25%;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #f3f3f3;
  font-size: 1rem;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.mailing-list form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mailing-list input[type="email"] {
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #f3f3f3;
  font-size: 1rem;
  border: 1px solid #ccc;
  width: 100%; 
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.mailing-list button {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background: var(--accent-color);
  color: var(--dark-bg);
  font-size: 1rem;
  border: none;
  cursor: pointer;
}

.home-page {
  position: relative;
}

.home-page .recent-articles, .home-page .left-trio, .home-page .center-trio, .home-page .right-trio, .home-page .coming-soon {
  position: relative;
  z-index: 1;
}

.home-page {
  width: 100%;
  color: var(--text-color);
  z-index: 99;
  position: relative;
  margin: 0 auto;
  padding: 75px 0;
  padding-bottom: 0px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-auto-rows: minmax(0px, auto);
  gap: var(--spacing);
}

.home-page section {
  background: var(--box-bg);
  padding: 2.5rem;
  border-radius: 15px;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.5rem;
  z-index: 1;
}

.home-page section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(240, 240, 240, 0.4);
}

.home-page header {
  background: var(--box-bg);
  padding: 0;
  border-radius: 15px;
  box-shadow: 0 5px 30px rgba(240, 240, 240, 0.75);
  transition: var(--transition);
  justify-content: space-between;
}

.home-page h1 {
  font-size: 3.5rem;
  text-align: center;
  color: var(--accent-color);
  border-bottom: 1px solid var(--accent-color);
  padding-bottom: 0;
  padding-top: 0.5rem;
  margin-top: 0;
}

.home-page section h2 {
  font-size: 2rem;
  color: var(--accent-color);
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 0.5rem;
  margin-top: 0;
}

.home-page section h3 {
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 0.5rem;
}

.home-page .header { grid-column: 8 / 18; grid-row: 1; }
.home-page .left1 { grid-column: 3 / 11; grid-row: 2; }
.home-page .right1 { grid-column: 15 / 23; grid-row: 3; }
.home-page .left2 { grid-column: 3 / 11; grid-row: 4; }
.home-page .right2 { grid-column: 15 / 23; grid-row: 5; }
.home-page .left3 { grid-column: 3 / 13; grid-row: 6; }
.home-page .right3 { grid-column: 13 / 23; grid-row: 6; }
.home-page .recent-articles { grid-column: 3 / 23; grid-row: 7; }
.home-page .left-trio { grid-column: 3 / 9; grid-row: 8; }
.home-page .center-trio { grid-column: 10 / 16; grid-row: 8; }
.home-page .right-trio { grid-column: 17 / 23; grid-row: 8; }
.home-page .coming-soon { grid-column: 3 / 23; grid-row: 9; }
.home-page .coming1 { grid-column: 3 / 8; grid-row: 10; }
.home-page .coming2 { grid-column: 8 / 13; grid-row: 10; }
.home-page .coming3 { grid-column: 13 / 18; grid-row: 10; }
.home-page .coming4 { grid-column: 18 / 23; grid-row: 10; }
.home-page .price-calculator { grid-column: 8 / 18; grid-row: 11; }
.home-page .mailing-list { grid-column: 8 / 18; grid-row: 12; }


.home-page .cta-button {
  background: var(--accent-color);
  color: var(--dark-bg);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: var(--transition);
}

.home-page .cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
}

.recent-articles {
  padding: var(--spacing);
  margin-top: var(--spacing);
  background: transparent;
}

.recent-articles .header-box {
  background: var(--header-bg);
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: var(--transition);
  padding: 1rem;
  margin-bottom: var(--spacing);
}

.recent-articles h2 {
  font-size: 2rem;
  color: var(--accent-color);
  text-align: left;
}

@media (max-width: 1024px) {
  .home-page {
    gap: calc(var(--spacing) / 2);
    grid-template-columns: 1fr;
  }
  .home-page section.left1,
  .home-page section.left2,
  .home-page section.left3,
  .home-page section.right1,
  .home-page section.right2,
  .home-page section.right3,
  .home-page section.recent-articles,
  .home-page section.left-trio,
  .home-page section.center-trio,
  .home-page section.right-trio,
  .home-page section.coming-soon,
  .home-page section.coming1,
  .home-page section.coming2,
  .home-page section.coming3,
  .home-page section.coming4,
  .home-page section.price-calculator,
  .home-page section.mailing-list,
  .home-page .header {
    grid-column: 1 / -1;
    margin: 0 var(--spacing);
    grid-row: auto;
  }

  .home-page section {
    margin-bottom: var(--spacing);
  }
}
@media (max-width: 850px) {
  :root {
    --spacing: 30px;
  }
}

@media (max-width: 768px) {
  .home-page h1 { font-size: 2.5rem; }
  .home-page h2 { 
    font-size: 1.5rem; 
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 0.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .home-page { font-size: 1rem; }
  
  .home-page section {
    padding: 1.5rem;
  }
}

footer {
  height: 100px;
  background-color: var(--footer-bg);
  color: var(--text-color);
  padding: 2rem 0;
  width: 100%; 
  z-index: 100;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  position: relative;
}

.footer-content {
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
  font-size: 1.25rem;
}

.footer-content .copyright { grid-column: 1; }
.footer-content .legal-links{ grid-column: 2; }
.footer-content .social-links { grid-column: 3; }

.legal-links a, .social-links a {
  margin: 0 0.5rem;
  color: var(--text-color); 
  text-decoration: none;
}

.social-links a {
  font-size: 1.5rem;
}

.recent-articles {
  padding: var(--spacing);
  margin-top: var(--spacing);
  background: transparent;
}

.recent-articles .header-box {
  background: var(--header-bg);
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: var(--transition);
  padding: 1rem;
  margin-bottom: var(--spacing);
}

.recent-articles h2 {
  font-size: 2rem;
  color: var(--accent-color);
  text-align: left;
}

.article-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-size: 18px;
  line-height: 1.6;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

.article-header {
  margin-bottom: 2rem;
}

.article-header h1 {
  font-size: 2.5rem;
  color: var(--accent-color);
  line-height: 1.2;
}

.article-content {
  flex: 1;
}

.article-intro {
  font-size: 1.1em;
  font-weight: 300;
  margin-bottom: 2rem;
}

.article-body h2 {
  font-size: 2rem;
  color: var(--accent-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.article-body ul {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.article-body li {
  margin-bottom: 0.5rem;
}

.article-conclusion {
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid var(--accent-color);
}

.article-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 0rem;
  padding-top: 0rem;
}

.nav-link {
  color: var(--accent-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid var(--accent-color);
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: var(--accent-color);
  color: var(--dark-bg);
}

@media (max-width: 768px) {
  .article-page {
    font-size: 16px;
    padding: 1rem;
  }

  .article-header h1 {
    font-size: 2rem;
  }

  .article-body h2 {
    font-size: 1.5rem;
  }

  .article-navigation {
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .article-page {
    font-size: 20px;
  }
}

.about-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  color: var(--text-color);
}

.about-page h1 {
  font-size: 2.5rem;
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 2rem;
}

.about-page h2 {
  font-size: 2rem;
  color: var(--accent-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.about-page h3 {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.about-page p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.about-page ul {
  list-style-type: none;
  padding-left: 0;
}

.about-page li {
  margin-bottom: 1rem;
}

.about-page section {
  margin-bottom: 1rem;
  background: var(--box-bg);
  padding: 1rem;
  border-radius: 15px;
  transition: var(--transition);
}

.about-page section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(240, 240, 240, 0.4);
}

@media (max-width: 768px) {
  .about-page {
    padding: 1rem;
  }

  .about-page h1 {
    font-size: 2.5rem;
  }

  .about-page h2 {
    font-size: 1.8rem;
  }

  .about-page h3 {
    font-size: 1.3rem;
  }
}

.faq-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--text-color);
}

.faq-page h1 {
  font-size: 3rem;
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 2rem;
}

.faq-page h2 {
  font-size: 2rem;
  color: var(--accent-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.faq-page h3 {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.faq-page p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.faq-section {
  margin-bottom: 2rem;
  background: var(--box-bg);
  padding: 2rem;
  border-radius: 15px;
  transition: var(--transition);
}

.faq-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(240, 240, 240, 0.4);
}

.faq-item {
  margin-bottom: 1.5rem;
}

.faq-item h3 {
  cursor: pointer;
}

.faq-item h3::before {
  content: '▶';
  display: inline-block;
  margin-right: 0.5rem;
  transition: transform 0.3s ease;
}

.faq-item.active h3::before {
  transform: rotate(90deg);
}

.faq-page .cta-button {
  display: inline-block;
  background: var(--accent-color);
  color: var(--dark-bg);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: var(--transition);
  margin-top: 1rem;
}

.faq-page .cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
}

@media (max-width: 768px) {
  .faq-page {
    padding: 1rem;
  }

  .faq-page h1 {
    font-size: 2.5rem;
  }

  .faq-page h2 {
    font-size: 1.8rem;
  }

  .faq-page h3 {
    font-size: 1.3rem;
  }
}

.contact-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--text-color);
}

.contact-page h1 {
  font-size: 3rem;
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 2rem;
}

.contact-page h2 {
  font-size: 2rem;
  color: var(--accent-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.contact-page h3 {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.contact-page p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.contact-page a {
  color: var(--text-color);
  text-decoration: none;
  position: relative;
  transition: var(--transition);
}

.contact-info, .faq-link, .mailing-list-section {
  background: var(--box-bg);
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 2rem;
  transition: var(--transition);
}

.contact-info:hover, .faq-link:hover, .mailing-list-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(240, 240, 240, 0.4);
}

.contact-method {
  margin-bottom: 1.5rem;
}

.social-links2 {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  font-size: 2rem;
}

.social-links2 li a {
  color: var(--accent-color);
  text-decoration: none;
  transition: var(--transition);
}

.social-links2 li a:hover {
  color: var(--text-color);
}

@media (max-width: 768px) {
  .contact-page {
    padding: 1rem;
  }

  .contact-page h1 {
    font-size: 2.5rem;
  }

  .contact-page h2 {
    font-size: 1.8rem;
  }

  .contact-page h3 {
    font-size: 1.3rem;
  }
}

.lazy-load-trigger {
  height: 1px;
  margin: 0;
  padding: 0;
  grid-column: 1 / -1;
}